<div class="row">
  <div class="col-12 pb-2 mb-5">
    <div class="bg-light p-4 pt-2 rounded">
      <h1>Copernicus Assisted <br /> Lake Water Quality Emergency Monitoring Service</h1>
      <p class="text-start lead">
        WQeMS provides an operational Water Quality Emergency Monitoring Service to the water utilities industry in
        relation with the quality of the “water we drink”. Exploiting Sentinel data, it provides quality monitoring of
        lakes valorized for the delivery of drinking water at a fine spatial resolution, following validated processes
        with in situ data. It focuses on slow and fast developing phenomena by offering Continuous Monitoring for a
        specified area; Alert Notifications in case some water quality parameter thresholds values are exceeded and in
        case water related issues are detected through social media; and On-demand Mapping with one-off geospatial maps
        of a selected area at a requested date.
        <br>
          Please read more about the <a href="https://wqems.eu/services/service-schemas">service schema.</a>
      </p>
      
    </div>
  </div>
</div>


<div class="row">
  <div class="col-12 col-md-6 pb-2 mb-5">
    <div class="bg-light p-4 pt-2 rounded">
      <div class="row align-items-center">
        <div class="col-12 col-sm-3 col-md-2 text-center">
          <img src="../../assets/img/continuous.png" class="img-fluid" alt="Continuous Monitoring">
        </div>
        <div class="col-12 col-sm-9 col-md-10">
          <h3>Continuous Monitoring</h3>
          <p class="text-start lead">
            Continuously monitors lakes, generates geospatial maps and detects water related-issues. Recommended for
            routinely operational monitoring and early-warning.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 pb-2 mb-5">
    <div class="bg-light p-4 pt-2 rounded">
      <div class="row align-items-center">
        <div class="col-12 col-sm-3 col-md-2 text-center">
          <img src="../../assets/img/ondemand.png" class="img-fluid" alt="Continuous Monitoring">
        </div>
        <div class="col-12 col-sm-9 col-md-10">
          <h3>On-demand Mapping</h3>
          <p class="text-start lead">
            Generation of one-off geospatial maps of a given area at a given time. Recommended for emergency management
            or
            for any type of issues spotted in a lake.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 pb-2 mb-5">
    <div class="bg-light p-2 rounded">
      <h4>WQeMS Overall Concept</h4>

      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-xxl-8 mt-3 mb-4 text-center">
          <img class="img-fluid" src="../../assets/img/platform.png">
        </div>
      </div>

    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 pb-2 mb-5">
    <div class="bg-light p-2 rounded">
      <h4>Platform overview</h4>

      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-xxl-8 mt-3 mb-4">
          <div class="ratio ratio-16x9">
            <iframe src="https://www.youtube.com/embed/rd7CJ-MMNeo?rel=0&modestbranding=0&showinfo=0" title="WQeMS introductory video"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 col-md-4 pb-2 mb-5">
    <div class="bg-light p-4 rounded text-center">
      <p class="lead mb-3 minH">
        <small>Let us know what you think about our service elements and our platform</small>
      </p>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSceBvavmm--0MJksTJ6BhnUiJYy7ME6EHQt3zPK6b1baq7o-w/viewform"
        target="_blank" class="btn btn-outline-danger">Take survey <fa-icon icon="comment-alt"></fa-icon></a>
    </div>
  </div>
  <div class="col-12 col-md-4 pb-2 mb-5">
    <div class="bg-light p-4 rounded text-center">
      <p class="lead mb-3 minH">
        <small>User guide</small>
      </p>
      <a href="/assets/docs/WQeMS_User_Guide_v1.pdf" target="_blank" class="btn btn-outline-danger" download>Read
        <fa-icon icon="book"></fa-icon></a>
    </div>
  </div>
  <div class="col-12 col-md-4 pb-2 mb-5">
    <div class="bg-light p-4 rounded text-center">
      <p class="lead mb-3 minH">
        <!-- <small>Supported by ONDA</small><br/> -->
        <small>Copernicus Data and Information Access Services (DIAS) - <a href="https://www.onda-dias.eu/cms/about/"
          target="_blank">ONDA</a></small>
      </p>
      <a href="https://www.onda-dias.eu/cms/marketplace/copernicus-assisted-lake-water-quality-emergency-monitoring-service/"
        target="_blank" class="btn btn-outline-danger">ONDA Showcase <fa-icon icon="bullhorn"></fa-icon></a>
    </div>
  </div>
</div>

<!--<div class="row">
  <div class="col-12 pb-2 mb-5">
    <div class="bg-light p-4 rounded text-center">
      <p class="lead mb-3">
        <small>Let us know what you think about our service elements and our platform.</small>
      </p>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSceBvavmm--0MJksTJ6BhnUiJYy7ME6EHQt3zPK6b1baq7o-w/viewform" target="_blank" class="btn btn-outline-danger"><fa-icon icon="comment-alt"></fa-icon> Take survey</a>
    </div>
  </div>
</div>-->

<div class="row">
  <div class="col-12 pb-2 mb-5">
    <div class="bg-light p-2 rounded">
      <h4>Service Components</h4>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 col-md-6 col-lg-3">

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Water Quality</h6>

        <div class="card-img-middle" style="background-image: url(https://wqems.eu/assets/images/transition.jpg);">
        </div>

        <a href="water-quality" class="btn btn-sm btn-outline-primary">Read more <fa-icon
            icon="arrow-right"></fa-icon></a>
      </div>
    </div>

  </div>
  <div class="col-12 col-md-6 col-lg-3">

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Bloom Events</h6>

        <div class="card-img-middle" style="background-image: url(https://wqems.eu/assets/images/bloom.jpg);"></div>

        <a href="bloom-event" class="btn btn-sm btn-outline-primary">Read more <fa-icon
            icon="arrow-right"></fa-icon></a>
      </div>
    </div>

  </div>
  <div class="col-12 col-md-6 col-lg-3">

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Land Water Transition Zone</h6>

        <div class="card-img-middle" style="background-image: url(https://wqems.eu/assets/images/lwt.jpg);"></div>

        <a href="land-water-transition-zone" class="btn btn-sm btn-outline-primary">Read more <fa-icon
            icon="arrow-right"></fa-icon></a>
      </div>
    </div>

  </div>
  <div class="col-12 col-md-6 col-lg-3">

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Extreme Events</h6>

        <div class="card-img-middle" style="background-image: url(https://wqems.eu/assets/images/oil.jpg);"></div>

        <a href="extreme-event" class="btn btn-sm btn-outline-primary">Read more <fa-icon
            icon="arrow-right"></fa-icon></a>
      </div>
    </div>

  </div>
</div>

<div class="row">
  <div class="col-12 p-2 mb-5">
    <div class="bg-light p-4 pt-2 rounded">
      <h4>Tools and Functionalities</h4>
    </div>
  </div>
</div>

<div class="row justify-content-center">
  <div class="col-12 col-md-6 col-lg-3">

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Map and Data Navigator</h6>

        <div class="card-img-middle" style="background-image: url(../../assets/img/_map_and_data_navigator.jpeg);">
        </div>

        <a href="map-and-data-navigator" class="btn btn-sm btn-outline-primary">Read more <fa-icon
            icon="arrow-right"></fa-icon></a>
      </div>
    </div>

  </div>
  <div class="col-12 col-md-6 col-lg-3">

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Catalogue</h6>

        <div class="card-img-middle" style="background-image: url(../../assets/img/_catalogue.jpeg);"></div>

        <a href="catalogue" class="btn btn-sm btn-outline-primary">Read more <fa-icon icon="arrow-right"></fa-icon></a>
      </div>
    </div>

  </div>
  <div class="col-12 col-md-6 col-lg-3">

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Data Access</h6>

        <div class="card-img-middle" style="background-image: url(../../assets/img/_data_access.jpeg);"></div>

        <a href="data-access" class="btn btn-sm btn-outline-primary">Read more <fa-icon
            icon="arrow-right"></fa-icon></a>
      </div>
    </div>

  </div>
  <div class="col-12 col-md-6 col-lg-3">

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Crowdsourcing Mobile App</h6>

        <div class="card-img-middle" style="background-image: url(../../assets/img/_crowdsourcing_mobile_app.jpeg);">
        </div>

        <a href="crowdsourcing-mobile-app" class="btn btn-sm btn-outline-primary">Read more <fa-icon
            icon="arrow-right"></fa-icon></a>
      </div>
    </div>

  </div>
  <div class="col-12 col-md-6 col-lg-3">

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Crowdsourcing Dashboard</h6>

        <div class="card-img-middle" style="background-image: url(../../assets/img/_crowdsourcing_dashboard.jpeg);">
        </div>

        <a href="crowdsourcing-dashboard" class="btn btn-sm btn-outline-primary">Read more <fa-icon
            icon="arrow-right"></fa-icon></a>
      </div>
    </div>

  </div>
  <div class="col-12 col-md-6 col-lg-3">

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Reports</h6>

        <div class="card-img-middle" style="background-image: url(../../assets/img/_reports.jpeg);"></div>

        <a href="reports" class="btn btn-sm btn-outline-primary">Read more <fa-icon icon="arrow-right"></fa-icon></a>
      </div>
    </div>

  </div>
  <div class="col-12 col-md-6 col-lg-3">

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Alerts</h6>

        <div class="card-img-middle" style="background-image: url(../../assets/img/_alerts.jpg);"></div>

        <a href="alerts" class="btn btn-sm btn-outline-primary">Read more <fa-icon icon="arrow-right"></fa-icon></a>
      </div>
    </div>

  </div>
  <div class="col-12 col-md-6 col-lg-3">

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Training</h6>

        <div class="card-img-middle" style="background-image: url(../../assets/img/_training.jpeg);"></div>

        <a href="training" class="btn btn-sm btn-outline-primary">Read more <fa-icon icon="arrow-right"></fa-icon></a>
      </div>
    </div>

  </div>

</div>

<div class="row">
  <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
    <a href="request-service/" type="button" class="btn btn-secondary btn-lg pt-2 pb-2">
      Request a Service <fa-icon icon="arrow-right" class="right"></fa-icon>
    </a>
  </div>
</div>