import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { RequestedServiceAPIsService, RequestedServiceProductTypeCompleteGetDTO } from 'src/app/services/restapiservices';
import * as L from 'leaflet';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-requested-service-details-modal',
  templateUrl: './my-requested-service-details-modal.component.html',
  styleUrls: ['./my-requested-service-details-modal.component.scss']
})
export class MyRequestedServiceDetailsModalComponent implements OnInit {

  @Input() public idRequestedService: any | undefined;
  public token: string = '';
  private map: any;
  public requestedServiceProductTypeCompleteGetDTO: RequestedServiceProductTypeCompleteGetDTO | undefined;
  @Input() public elaborated: boolean | undefined;
  private serviceMapping = new Map<string, string>();

  constructor(private activeModal: NgbActiveModal, private requestedServiceAPIsService: RequestedServiceAPIsService, private keycloakService: KeycloakService, private router: Router) { }

  private initMap(): void {
    console.log(this.elaborated)
    console.log("HERE")
    this.map = L.map('map',  { center: [ this.requestedServiceProductTypeCompleteGetDTO?.waterBody?.lon as number, this.requestedServiceProductTypeCompleteGetDTO?.waterBody?.lat as number],zoom: 12});
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });
    tiles.addTo(this.map);
    this.map.attributionControl.setPrefix(false);
  }
  
  ngOnInit(): void {
    console.log(this.elaborated)
    this.keycloakService.getToken().then(token => {
      this.token = token;
      this.requestedServiceAPIsService.getRequestedServiceCompleteByUserAndIdRequestedServiceApiV1RequestedServiceByuserCompleteIdRequestedServiceGet(this.idRequestedService, token).subscribe(data => {
        console.log(data)
        this.requestedServiceProductTypeCompleteGetDTO = data;
        this.initMap()
      })
    })

    this.serviceMapping.set("waterquality-tur", "water-quality/tur/");
    this.serviceMapping.set("waterquality-chl", "water-quality/chl/");
    this.serviceMapping.set("waterquality-sst", "water-quality/sst/");
    this.serviceMapping.set("waterquality-cdm", "water-quality/cdm/");
    this.serviceMapping.set("waterquality-sdd", "water-quality/sdd/");
    this.serviceMapping.set("bloomevent-hab", "bloom-event/hab/");
    this.serviceMapping.set("landwatertransitionzone-twodates", "land-water-transition-zone/twodates/");
    this.serviceMapping.set("landwatertransitionzone-hydroperiod", "land-water-transition-zone/hydroperiod/");
    this.serviceMapping.set("extremeevent-flood", "extreme-event/flood/");
    this.serviceMapping.set("extremeevent-oilspill", "extreme-event/oilspill/");
    this.serviceMapping.set("extremeevent-muddywater", "extreme-event/muddywater/");
  
  }

  closeModal() {
    this.activeModal.dismiss()
    this.activeModal.close('Modal Closed');
  }

  onClickMakeNewRequestForThisWaterBody() {
    this.closeModal()
    this.router.navigateByUrl('request-service/' + this.requestedServiceProductTypeCompleteGetDTO?.waterBody?.localId)
  }

  buildFileServerURL(serviceName: string, localId: string) {
    var partialURL = this.serviceMapping.get(serviceName);
    console.log(partialURL += localId)
    console.log(serviceName);
    console.log(localId);
    return 'https://cog-wqems.opsi.lecce.it/' + partialURL; 
  }
}
