<nav class="navbar navbar-expand-lg navbar-dark bg-primary-dark navbar-expand-md fixed-top">
  <div class="container-fluid">

    <a class="navbar-brand" href="home">
      <img src="../../assets/img/logowqems2.png" alt="WQeMS" width="60" height="60"
        class="d-inline-block align-text-top">
    </a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0" routerLinkActive="menu-item-active">
        <li class="nav-item" >
          <a class="nav-link" href="data-access">Data Access</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="training">Training</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="about-copernicus-services">Copernicus Services</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.geoportal.org/?f:sources=WQeMSid&f:source=WQeMSid&m:activeLayerTileId=osm&f:dataSource=dab">GEOSS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://wqems.eu/" target="_blank">About WQeMS</a>
        </li>

        <!-- User Menu / Start -->
        

        <li class="nav-item dropdown user-menu" *ngIf="this.isLoggedIn;else login_button">
          <a class="nav-link dropdown-toggle" href="#" id="userMenuMobile" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            <fa-icon icon="user-circle"></fa-icon>
          </a>
          <ul class="dropdown-menu" aria-labelledby="userMenuMobile">

            <ng-container *ngIf="userProfile">
              <li *ngIf="userProfile.firstName">
                <a class="dropdown-item" href="#">
                  Hi, <strong>{{ userProfile.firstName }} {{ userProfile.lastName }}</strong>!
                </a>
              </li>
                <hr class="dropdown-divider">
            </ng-container>

            <!--<li *ngIf="isAdministrator"><a class="dropdown-item"  href="administration-dashboard">
              <fa-icon icon="th-large"></fa-icon> Admin Dashboard</a></li>-->
            <li *ngIf="isAdministrator"><a class="dropdown-item" href="administration-requests-list">
                <fa-icon icon="cog"></fa-icon>Manage Service Requests
              </a></li>
            <li><a class="dropdown-item" href="my-requested-service">
                <fa-icon icon="share-square"></fa-icon>Requested Services
              </a></li>
              <li><a class="dropdown-item" href="my-alerts">
                <fa-icon icon="exclamation-triangle"></fa-icon>Alerts
              </a></li>
            <li><a class="dropdown-item" href="request-report">
                <fa-icon icon="file-lines"></fa-icon>Reports
              </a></li>
            
            <!--<li><a class="dropdown-item" href="#">
              <fa-icon icon="file-text"></fa-icon>My Requested Reports</a></li>-->
            <li>
              <hr class="dropdown-divider">
            </li>
            <li><a class="dropdown-item" href="my-alert-configurations">
              <fa-icon icon="sliders"></fa-icon>Alert Configurations
            </a></li>
            <li><a class="dropdown-item" target="_blank"
              href="https://idm-wqems.opsi.lecce.it/auth/realms/wqems/account/#/">
              <fa-icon icon="user"></fa-icon>My Profile
            </a></li>
            <li><a class="dropdown-item" (click)="onClickSignOut()">
                <fa-icon icon="sign-out"></fa-icon>Sign Out
              </a></li>
          </ul>
        </li>
        <!-- User Menu / End -->

        <!-- User Menu Login / Start -->
        <ng-template #login_button>
          <li class="nav-item user-menu login">
            <button (click)="onClickLogin()" class="btn btn-outline-light" type="button">
              <fa-icon icon="sign-in"></fa-icon> Log In
            </button>
          </li>
        </ng-template>
        <!-- User Menu Login / End -->

      </ul>

    </div>
  </div>
</nav>