<h2>Privacy policy pursuant to Article 13 of the GDPR</h2>
<br>
<p>
    This privacy policy concerns the WQeMS Web Portal and explains what information we collect in respect to users’ 
    registration and requests for services in the web portal. In addition, it includes also information about information storage, 
    use, transfer and erasure. All the data processing adheres to the EU General Data Protection Regliation (2016/679).
</p>
<br>
<div class="policy-section">
    <h4>Who is the data controller?</h4>
    <p>
        Engineering Ingegneria Informatica S.p.a., with its registered office in Rome, Piazzale dell’Agricoltura 24-00144, is the 
        data controller for the WQeMS Web Portal reachable on <a href="https://portal-wqems.opsi.lecce.it/">https://portal-wqems.opsi.lecce.it/</a> regarding the processing of personal data concerning you.  

        The Data Protection officer can be contacted via this email address: <a href="mailto:dpo.privacy@eng.it">dpo.privacy@eng.it</a>
        <br><br>
        <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#dataController" aria-expanded="false" aria-controls="collapseExample">
        READ MORE
        </button>
    </p>
    <div class="collapse" id="dataController">
        <div class="card card-body">
            The data processing carried out by Engineering is based on the principles of: <br><br>
            <ul>
                <li>lawfliness</li>
                <li>fairness</li>
                <li>transparency</li>
                <li>restriction of purpose and retention</li>
                <li>minimisation of data</li>
                <li>accuracy</li>
                <li>integrity</li>
                <li>confidentiality for the protection of the online privacy of its users.</li>
            </ul>
        </div>
    </div>
</div>
<div class="policy-section">
    <h4>What data will be processed?</h4>
    <p>
        Browsing data (IP address), E-mail address provided voluntarily by the user for information requests, cookies and other related technologies.
        <br><br>
        <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#dataProcessed" aria-expanded="false" aria-controls="collapseExample">
        READ MORE
        </button>
    </p>
    <div class="collapse" id="dataProcessed">
        <div class="card card-body">
            The data processing carried out by Engineering is based on the principles of: <br><br>
            <ul>
                <li><strong>Browsing data</strong>: this is information that is not collected to be specifically connected to identified users, 
                    but which by their nature could, through processing and association with data held by third parties, allow you to identify users. <br>
                    This category of data includes the IP addresses or domain names of the computers used by users connecting to the website, 
                    the URI (Uniform Resource Identifier) address of the resources requested, the time of the request, the method used to submit the request 
                    to the server, the method used to submit the request to the server, the size of the file obtained in response, the numerical code 
                    indicating the status of  the response given by the server (successful, error, etc.) and other parameters related to the operating system 
                    and the user’s computer environment.                    
                </li>
                <li><strong>Data provided voluntarily by the user for service requests:</strong> e-mail address for sending alerts and reports</li>
                <li><strong>Cookies and other related technologies:</strong> Engineering collects some information through cookies. More information on the use of cookies and other similar technologies can be found here.</li>
            </ul>
        </div>
    </div>
</div>
<div class="policy-section">
    <h4>Why does Engineering use your personal data?</h4>
    <p>
        Engineering primarily uses your data to check the correct functioning of the website, to provide services requested by you, to fulfil legal, accounting and tax obligations.
        <br><br>
        <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#dataMotivation" aria-expanded="false" aria-controls="collapseExample">
        READ MORE
        </button>
    </p>
    <div class="collapse" id="dataMotivation">
        <div class="card card-body">
            Below is a list of the purposes for which Engineering uses your personal data and the relevant legal bases for these: <br><br>
            <ul>
                <li><strong>Purpose:</strong> to obtain anonymous statistical information on the use of the website and to check its correct 
                    functioning and to identify any anomalies and/or abuses. <br>
                    <strong>Legal Basis:</strong> necessary for the purposes of the legitimate interest pursued by Engineering in order to check 
                    the proper functioning of the website and identify any anomalies and/or abuses [Article 6(1)(f) GDPR].                     
                </li>
                <li><strong>Purpose:</strong> to provide services requested in the test phase of the research project for the action entitled 
                    "Copernicus Assisted Lake Water Quality Emergency Monitoring Service" - "WQeMS" ("action").<br>
                    <strong>Legal Basis:</strong> necessary for the performance of a contract [Article. 6(1)(b) GDPR] The provision of Personal Data 
                    for this purpose is optional but failure to provide them would make it impossible to receive additional information (alerts and reports) 
                    provided by the WQeMS platform.
                </li>
                <li><strong>Purpose:</strong> to fulfil any legal, accounting and tax obligations.<br>
                    <strong>Legal Basis:</strong> this processing is necessary to comply with a legal obligation to which Engineering is subject under Article 6(1)(c) of Regulation.
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="policy-section">
    <h4>With whom will your personal data be shared?</h4>
    <p>
        Your data will be shared with subjects acting as data processors, subjects and authorities to whom it is compulsory to communicate your personal data 
        according to the legal provisions and persons authorised by Engineering to process personal data.
        <br><br>
        <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#sharingData" aria-expanded="false" aria-controls="collapseExample">
        READ MORE
        </button>
    </p>
    <div class="collapse" id="sharingData">
        <div class="card card-body">
            <ul>
                <li><strong>The subjects that typically act as data processors are:</strong></li>
                <ul>
                    <li>persons, companies or professional firms that provide assistance and advice to Engineering in terms of accounting, 
                        administrative, legal, tax, financial and debt collection matters related to the provision of Services</li>
                    <li>subjects with whom it is necessary to interact for the provision of Services</li>
                    <li>subjects delegated to carry out technical maintenance activities (including the maintenance of network equipment and electronic communications networks).</li>
                </ul>
                <li><strong>your data may be disclosed to subjects, bodies or authorities to whom it is mandatory to communicate your personal data 
                    pursuant to legal provisions or by order of the authorities.</strong></li>
                <li><strong>The persons authorised by Engineering to process Personal Data are, for example, employees of Engineering who physically 
                    carry out personal data processing operations.</strong></li>
            </ul>
        </div>
    </div>
</div>
<div class="policy-section">
    <h4>How long is your data stored for?</h4>
    <p>
        We do not retain your data for longer than necessary for the purposes set out in this Policy.
        <br><br>
        <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#storingTime" aria-expanded="false" aria-controls="collapseExample">
        READ MORE
        </button>
    </p>
    <div class="collapse" id="storingTime">
        <div class="card card-body">
            The Personal Data processed will be kept for the time that is strictly necessary to provide the services requested by you, or by 
            the end of the planned test phase of the research project called "Copernicus Assisted Lake Water Quality Emergency Monitoring Service" — "WQeMS" ("action"),
            with planned duration until June 2023.<br><br>
            The Personal Data processed for purposes relating to legal, accounting and tax obligations will be kept until the time provided for by 
            the specific obligation or applicable law.            
        </div>
    </div>
</div>
<div class="policy-section">
    <h4>Transfer of personal data outside of the European Economic Area</h4>
    <p>
        We do not transfer your personal data outside the European Union (EU) or the European Economic Area (EEA).
    </p>
</div>
<div class="policy-section">
    <h4>What are your rights?</h4>
    <p>
        You always have the right to ask Engineering to access your data and obtain a copy, edit or delete it. 
        You can also object to the processing of your data or request a restriction of processing.
        <br><br>
        <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#rights" aria-expanded="false" aria-controls="collapseExample">
        READ MORE
        </button>
    </p>
    <div class="collapse" id="rights">
        <div class="card card-body">
            Pursuant to Articles 15 et seq. of the Regulation, you have the right, at any time, to ask Engineering for access to your Personal Data, 
            the correction or deletion of this data as well as to object to its processing, you have the right, pursuant to Article 18 of the Regulation, 
            to request a restriction of processing of your data, as well as to obtain this data in a structured, commonly-used and machine-readable form 
            as outlined in Article 20 of the Regulation.<br><br>
            Requests should be addressed in writing to Engineering via the following email address: <a href="mailto:dpo.privacy@eng.it">dpo.privacy@eng.it</a><br>
            In any case, you have the right to lodge a complaint with the relevant Supervisory Authority (Personal Data Protection Authority), 
            pursuant to Article 77 of the Regulation, if you believe that the processing of your Personal Data is in violation of existing legislation.
        </div>
    </div>
</div>
<div class="policy-section">
    <h4>Changes to our privacy policy</h4>
    <p>
        We may periodically update this Privacy Policy. Please refer to the “Updated on” legend at the top of this page to see when this Privacy Policy was last revised. 
    </p>
</div>
<div class="policy-section">
    <h4>Contact </h4>
    <p>
        If you have any questions about how we use your personal data that are not answered here, or if you want to exercise your rights regarding your personal data, please e-mail us at: <a href="mailto:dpo.privacy@eng.it">dpo.privacy@eng.it</a>.
    </p>
</div>