<!-- Sezione Titolo + 1° Testo -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
            <h3>Web Services</h3>
            <p class="text-start lead">
                WQeMS allows interaction with the system via OGC protocols. 
                In particular, using The <a target="_blank" href="https://www.ogc.org/standards/wms">OGC Web Map Service (WMS)</a> specification, that defines an HTTP interface for requesting georeferenced map images from a server, the user can obtain the list of layers for which he have permissions. 
                In fact, by invoking the WMS's <a target="_blank" href="https://docs.geoserver.org/latest/en/user/services/wms/reference.html#getcapabilities">GetCapabilities</a> request, the server will respond with some information including list of data layers that can be accessed. 
                It is important to explain some fields in the response, which is in the form of an XML file. In particular:
                <ul class="text-start lead">
                    <li>The <strong>Keywords</strong> in the <strong>KeywordList</strong> field give a range of brief information on service, sub-service (which is the specific parameter monitored), unit of measure, name and identifier of the water body.</li>
                    <li>The <strong>Dimension</strong> attribute is very important, because it shows the granules available for that layer. Indeed, a layer can have several granules based on the satellite, resolution and date to which the spatial image of the granule is referenced. Taking advantage of the GetMap request, the user can specify in the requesting URL a <strong>dimension_granule</strong> attribute which should be valorized with the granule of interest.</li>
                    <li>GetMap is not the only way to access selected data products. It is possible to access files through a FileServer as well. In this regard, the <strong>DataURL</strong> attribute in the GetCapabilities XML response helps to figure out the location of that file. Having identified the granule of interest from the previous point, in fact, the user can substitute the string that identifies the granule into the template URL found in the DataURL field. This will directly access the granule in tif format.</li>
                    <li>Similarly to what happens with DataURL, it is possible to extract the metadata in XML format assigned to a specific granule by substituting the granule's identifying string in the <strong>MetadataURL</strong> field of the XML response of the GetCapabilities request.</li>

                </ul>
                <p class="text-start lead">For each of these layers, there is also the possibility of displaying them via a WQeMS <a target="_blank" href="https://docs.geoserver.org/latest/en/user/services/wms/reference.html#getmap">GetMap</a> request by specifying the layer name, style, format and time dimensions.</p>
        </div>
    </div>
</div>

<!-- Sezione Carousel -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 mb-5 rounded">

            <div class="row justify-content-center">
                <div class="col-12 col-xl-10">
            
                        <ngb-carousel *ngIf="images">
                            <ng-template ngbSlide *ngFor="let image of images">
                                <div class="picsum-img-wrapper">
                                    <img [src]="image" alt="WQeMS">
                                </div>
                            </ng-template>
                        </ngb-carousel>
            
                </div>
            </div>

        </div>
    </div>
</div>

<!-- Sezione 2° Testo -->
<!-- <div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mt-4 mb-5 rounded">
            <p class="text-start lead">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore
                magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut labore
                et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt
                ut labore et dolore magna aliqua.
            </p>
        </div>
    </div>
</div> -->

<!-- Sezione Bottone -->
<div class="row">
    <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
        <a target="_blank" href="https://geoserver-wqems.opsi.lecce.it/geoserver/wms?service=wms&version=1.3.0&request=GetCapabilities&format=application/vnd.ogc.wms_xml" class="btn btn-secondary btn-lg pt-2 pb-2">
            Try GetCapabilities Now <fa-icon icon="arrow-right" class="right"></fa-icon>
        </a>
    </div>
</div>