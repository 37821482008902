import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { url } from 'inspector';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private keycloakUrlGetToken: string = 'https://idm-wqems.opsi.lecce.it/auth/realms/wqems/protocol/openid-connect/token/'

  constructor(private httpClient: HttpClient) { }

  getTokenByClientIdAndClientSecret(idClient: string, clientSecret: string): Observable<any> {
    let body = new URLSearchParams();
    body.set('client_id', idClient);
    body.set('grant_type', "client_credentials");
    body.set('client_secret', clientSecret);

    let options = {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    console.log(body.toString())

    return this.httpClient.post(this.keycloakUrlGetToken, body.toString(), options)
  }

}