<h2>Terms and conditions</h2>
<br>
<div class="terms-section">
    <h4>Introduction</h4>
    <p>
        Engineering Ingegneria Informatica S.p.A., with a registered office in Rome, in Piazzale dell'Agricoltura no. 24, Tax Code 00967720285 and 
        VAT number 05724831002 (hereinafter also referred to as the "Company" or "Engineering), in the context of the European research project WQeMS 
        (Grant Agreement No 101004157) provides WQeMS services to those who request, by registering, the access on the website called portal-wqems.opsi.lecce.it 
        (also the "WQeMS Web Portal" or "Web portal"). These general conditions of use of the Web portal (hereinafter referred to as 
        "Terms and Conditions of Usage") constitute the set of rules and provisions governing access and usage of the Web portal. 
        These Terms and Conditions of Usage are valid and effective towards any user, who access and/or register on the Web Portal (collectively, "User" or "Users"). 
        These Terms and Conditions of Usage is valid till the end of the WQeMS project execution (i.e. August 2023). 
        By that end, the Web Platform will be provided to the Users as a prototype implemented during the research project and its usage will be referred 
        in the context of test and validation of its functionalities. 
    </p>
</div>
<div class="terms-section">
    <h4>Object of the Agreement</h4>
    <p>
        Through the Web Portal, registered Users can request WQeMS services for the quality monitoring of lakes valorized for the delivery of drinking water.
    </p>
</div>
<div class="terms-section">
    <h4>Registration</h4>
    <p>
        In order to use the Web portal, the User shares certain personal browsing data, such as IP address, while for requesting services, the User shall 
        be assigned with specific non-personalized credentials. Registration is essential and without it, the User will not be able to request services.<br><br>
        If the user wants to receive additional notifications (alerts and reports), it will be asked to leave an e-mail address. Without its provision, 
        it will be impossible to receive additional information. <br><br>
        By accessing the website and registering with assigned credentials, the User guarantees to have read and understood the Privacy and Cookie Policy, 
        as well as these Terms and Conditions of Usage. All of the stated documents are available at any time and to any user by clicking on the links at the bottom 
        of the website homepage. <br><br>
        The User acknowledges that he is solely responsible for maintaining the confidentiality of his e-mail address and any access credentials. 
        The User undertakes the obligation to immediately inform the Company of any loss or unauthorized use of its e-mail address and/or login credentials. 
        The Company cannot be held responsible for any damages deriving from and/or connected to the failure to comply with this obligation.<br><br>
        In the event of a user's violation of one or more terms of these Terms and Conditions of Usage, the Company has the right to prevent, 
        at its sole discretion, the access to the Web portal and/or completion of any other action.
    </p>
</div>
<div class="terms-section">
    <h4>Personal data processing</h4>
    <p>
        Navigation trough the Web portal as well as the request to receive additional notifications results in the processing of user's personal data by the Company.
        For detailed information on the purposes and means of the personal data processed by Engineering, please refer to the <a href="privacy-policy">Privacy Policy</a>
         and <a href="cookie-policy">Cookie Policy</a> provided at the time of registration and published on the Web Portal.        
    </p>
</div>
<div class="terms-section">
    <h4>Link to third party sites</h4>
    <p>
        The Web portal contains certain links to websites that allow the User to terminate his or her browsing on the Web portal 
        and connect to other websites, pages and content of third parties. Engineering hosts such links as a mere convenience for the respective 
        third parties and this mere hosting does not, in any case, imply Company's approval of the content to which the referred links lead.<br><br>
        The User is aware that the Company: does not control, examine, verify, approve or respond to any material, content, service provided for, 
        and/or offered by third parties, including those of their suppliers and sub-suppliers on their respective websites and the websites connected to them; 
        does not provide any guarantees and is not responsible for such materials, contents, services; the User is the only solely responsible for the materials, 
        contents and services to which he accesses through third-party websites.               
    </p>
</div>
<div class="terms-section">
    <h4>Duration - Unauthorized Use</h4>
    <p>
        The present Terms and conditions of Usage are valid and effective for an indefinite period of time.<br><br>
        The Company reserves the right to close the Web Portal, interrupt access and the usage of the Web portal and delete the data provided until 
        the Portal is closed. The company may do so freely, without a need to provide any explanation, at any time and without any notice.<br><br>
        Without any prejudice to the right to terminate the present agreement in the cases prescribed by positive law or for the violation of these 
        Terms and Conditions, the Company reserves the right to, at its sole discretion and without any notice, suspend or terminate, permanently or temporarily, 
        access to and use of the Portal to the individual User, in the situation where the User: (a) does not provide or has not provided personal data 
        that is up to date, complete, true, correct and legitimate; (b) engages in actions or behaviours that are illegal or, according to the opinion of Engineering, 
        in a manner considered to be incompatible with the purpose of the Web portal (such as, but not limited to, transmission and / or exchange of computer viruses; 
        sending or dissemination of advertising; chain mail or pyramid schemes; spamming or junk mailing; transmission and / or exchange and / or disclosure of 
        illegal material or in any case of racist, libellous or defamatory, threatening, vulgar, obscene content); (c) violates or has violated these 
        Terms and Conditions of Usage.<br><br>
        The Company will not be liable in any case to the User and third parties for the suspension/termination of the Web portal.
                     
    </p>
</div>
<div class="terms-section">
    <h4>Limitation of Liability</h4>
    <p>
        Subject to the mandatory limits of the law, Engineering shall have no liability to the User in the event of a disservice, delay, malfunction, 
        suspension and/or interruption of the usage of the services as well as in relation to facts, conduct and circumstances not attributable to Engineering 
        itself (such as infringement of intellectual property rights of third parties, the accuracy of the information, malfunctioning of the connection equipment 
        used by the User or incorrect usage of the Web portal by the User) or, in general, the occurrence of force majeure or unforeseeable circumstances.<br><br>
        The User undertakes an obligation to indemnify the Company and does not hold it liable at any situation, at its first and simple request and without 
        any exception, from all claims, requests, actions, costs, expenses (including legal ones) or charges that derive from the User's failure to comply with 
        the provisions of these Terms and Conditions of Usage, without prejudice to rights for other available remedies available to Engineering pursuant to 
        these Terms and Conditions of Usage and/or the applicable legislation, including the ones with the regulatory nature.             
    </p>
</div>
<div class="terms-section">
    <h4>Suspension and Interruption of Services</h4>
    <p>
        The Company reserves, without assuming any responsibility, the right to temporarily suspend the Services in case of congestion and/or 
        overload of the system, as well as to guarantee ordinary and extraordinary maintenance. Where applicable and possible, The Company undertakes an 
        obligation to inform the users about the planned temporary suspensions by means of a suitable notice on the Web portal.
    </p>
</div>
<div class="terms-section">
    <h4>Applicable law and competent court</h4>
    <p>
        These Terms and Conditions of Usage are governed and must be exclusively interpreted according to Italian law. All disputes arising from 
        these Terms and Conditions of Usage, including those relating to its validity, interpretation, enforcement and resolution, or in any case 
        connected to them, will be in the jurisdiction of the competent Court of Rome.
    </p>
</div>
<div class="terms-section">
    <h4>Updates of the General Conditions</h4>
    <p>
        The Company reserves the right to modify these Terms and Conditions of Usage at any time and to adapt them to supervening administrative, 
        economic and/or technical-managerial needs and to the relevant changes in the applicable legislation, by notifying the User via Web portal publication.
    </p>
</div>