import { KeycloakService } from "keycloak-angular";

export function initializeKeycloak(
  keycloak: KeycloakService
  ) {
    return () =>
    keycloak.init({
        config: {
            url: 'https://idm-wqems.opsi.lecce.it/auth/',
            realm: 'wqems',
            clientId: 'wqems-service-management-portal'
        },
        initOptions: {
          onLoad: 'check-sso',
          pkceMethod: 'S256', 
          // must match to the configured value in keycloak
          // redirectUri: 'http://localhost:4200/sign-in',   
          // this will solved the error 
          checkLoginIframe: false
        }});
}