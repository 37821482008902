<!-- Sezione Titolo + 1° Testo -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
            <h3>Account Developer</h3>
            <p class="text-start lead">
                A user can apply for a developer account to integrate an external system (e.g. DSS) with WQeMS. Through a developer account, a system can interact using HTTP APIs. Through API access, in fact, a system can interact with WQeMS directly using the HTTP protocol.  A documentation of all available APIs can be found at <a href="https://dev-api-wqems.opsi.lecce.it/docs#/" target="_blank">this link</a>. To apply for a developer account, the user must be registered on the platform. 
            </p>      
        </div>
    </div>
</div>

<!-- Sezione Carousel -->
<div class="row">
    
    <div class="col-12">
        <div class="bg-light p-4 mb-5 rounded">

            <div class="row justify-content-center">
                <div class="col-12 col-xl-10">
            
                        <iframe width="100%" height="600" src="https://dev-api-wqems.opsi.lecce.it/redoc"></iframe>
            
                </div>
            </div>

        </div>
    </div>
</div>


<!-- Sezione 2° Testo -->
<!-- <div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mt-4 mb-5 rounded">
            <p class="text-start lead">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore
                magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut labore
                et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt
                ut labore et dolore magna aliqua.
            </p>
        </div>
    </div>
</div> -->

<!-- Sezione Bottone -->
<div class="row">
    <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
        <button (click)="infoModalService.openInfoModal('Apply for a developer account', 'Contact us to enable this functionality.')" class="btn btn-secondary btn-lg pt-2 pb-2">
            Apply for a Developer Account <fa-icon icon="arrow-right" class="right"></fa-icon>
        </button>
    </div>
</div>