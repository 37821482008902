<!-- Sezione Titolo + 1° Testo -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
            <h3>Catalogue</h3>
            <p class="text-start lead">
                The Catalogue is an entrypoint that exposes the <strong>list of service activations and related metadata</strong>
                produced by WQeMS on the different AOIs requested by registered users. It enables on one hand to
                understand which kind of information the WQeMS system can generate, by looking at the many entities that
                populates the catalogue. On the other hand, it is another way for registered users to search and reach
                data products to which they have access to.
                The Catalogue interface shows in the homepage a navigable list of metadata entities, which can be
                filtered or sorted by different means. A search bar on the upper part of the website facilitates the
                navigation among the product’s descriptions.
                The key features offered by the Catalogue are the following:
            </p>
            <ul class="text-start lead">
                <li>List information and metadata of service activations and results requested by WQeMS users</li>
                <li>Download of XML metadata files</li>
                <li>Search and filter service activations by keyword and time intervals</li>
            </ul>
        </div>
    </div>
</div>

<!-- Sezione Carousel -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 mb-5 rounded">

            <div class="row justify-content-center">
                <div class="col-12 col-xl-10">
            
                        <ngb-carousel *ngIf="images">
                            <ng-template ngbSlide *ngFor="let image of images">
                                <div class="picsum-img-wrapper">
                                    <img [src]="image" alt="WQeMS">
                                </div>
                            </ng-template>
                        </ngb-carousel>
            
                </div>
            </div>

        </div>
    </div>
</div>

<!-- Sezione Galleria Fotografica -->
<!--<div class="row">

    <div class="col-12 col-md-6 col-lg-4">
        <div class="card">
            <a href="#">
                <img src="../../assets/img/catalogue_shot_1.png" class="card-img-top">
            </a>
        </div>
    </div>

    <div class="col-12 col-md-6 col-lg-4">
        <div class="card">
            <a href="#">
                <img src="../../assets/img/catalogue_shot_2.png" class="card-img-top">
            </a>
        </div>
    </div>

    <div class="col-12 col-md-6 col-lg-4">
        <div class="card">
            <a href="#">
                <img src="../../assets/img/catalogue_shot_3.png" class="card-img-top">
            </a>
        </div>
    </div>

</div>-->

<!-- Sezione 2° Testo -->
<!-- <div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mt-4 mb-5 rounded">
            <p class="text-start lead">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore
                magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut labore
                et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt
                ut labore et dolore magna aliqua.
            </p>
        </div>
    </div>
</div> -->

<!-- Sezione Bottone -->
<div class="row">
    <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
        <a href="https://catalogue-wqems.opsi.lecce.it/" class="btn btn-secondary btn-lg pt-2 pb-2" target="_blank">
            Go to the Catalogue <fa-icon icon="arrow-right" class="right"></fa-icon>
        </a>
    </div>
</div>